<template>
<div v-if='cardData.kind && cardData.kind === `room_lunch`'
     class='b-plan_table__label__wrapper--disabled'>
    <div class='h-font-12 h-fw-500 h-opacity-0_5 h-flex h-flex-center'>
        <span class='h-mr-5'>
            {{ cardData.title }}
        </span>
    </div>
</div>
<div v-else-if='cardData.title === `reserved_slot_drive`'
     class='b-reserved-slot-card'>
    <div class='b-reserved-slot-card__title'>
        {{ $t('CALENDAR.POPUP.RESERVED.SLOT.DRIVE.BACK.TITLE') }}
    </div>
</div>
<div v-else-if='cardData.origin === `from_reserved_slot`'
     class='b-reserved-slot-card'>
    <div class='b-reserved-slot-card__title'>
        {{ $t('CALENDAR.POPUP.RESERVED.SLOT.TITLE') }}
    </div>
</div>
<drag v-else
      class='drag'
      tag='div'
      :style='styles'
      :transfer-data='{ card: cardData }'
      @dragstart='data => $emit("dragstart", data)'
      @dragend='data => $emit("dragend", data)'
      @drag='data => $emit("drag", data)'>
    <div class='b-plan_table__label__wrapper'
         :class='{
             "b-plan_table__label__wrapper--drive": isDriveEvent,
             "b-plan_table__label__wrapper--small": eventHeight <= 15
         }'>
        <div class='b-plan_table__label'
             :class='{"b-plan_table__transparent": isUnavailabilityEvent }'>
            <div v-if='time && !isTimeHidden'
                 class='b-plan_table__label--header qa-plan-table-card__time'>
                {{ time }}
            </div>
            <div class='h-bold h-overflow-hidden'>
                <p v-if='isUnavailabilityEvent'
                   class='h-m-0 b-plan_table__label--small qa-plan-table-card__unavailability-title'
                   :class='{"b-plan_table__label__overflow-text": eventHeight <= 70}'>
                    {{ $t(unavailabilityTitle) }}
                </p>
                <p v-else-if='isDriveEvent'
                   class='h-m-0 qa-plan-table-card__unavailability-title'
                   :class='{"b-plan_table__label__overflow-text": eventHeight <= 70}'>
                    🚗 {{ $t('EVENT.DRIVE.TITLE') }}
                </p>
                <p v-else
                   class='h-m-0 qa-plan-table-card__title'
                   :class='{"b-plan_table__label__overflow-text": eventHeight <= 70}'>
                    {{ privateEvent ? $t('EVENT.PRIVATE.TITLE') : cardData.title }}
                </p>
            </div>
            <div :style='{ background: color }'
                 class='b-plan_table__label__before'></div>
            <div v-if='cardData.origin === `from_appointment` && cardData.booking_platform !== undefined'
                 class='b-plan_table__label__appointment'
                 :class='accountBookedTypeClass'>
            </div>
        </div>
    </div>
</drag>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import { Drag } from 'vue-drag-drop';
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { CalendarInstanceType } from '@/types/Events/CalendarInstanceType';
import DateMixin from '@/mixins/dateMixin';
import { EVENT_ORIGIN_DRIVE } from '@/helpers/events';
import { unavailabilityTitleTranslate } from '@/helpers/translate';

@Component({
    components: { Drag },
})
export default class PlanTableCard extends Mixins(DateMixin) {
    @Prop({ type: Object, required: true }) readonly cardData!: CalendarInstanceType;

    isTimeHidden: boolean = false;

    get styles() {
        return {
            height: this.cardData.full_day ? `38px` : `${this.eventHeight}px`,
            transform: `translate(0, ${dayjs(this.cardData.dt_start).minute()}px)`,
        };
    }

    get eventHeight(): number {
        return dayjs(this.cardData.dt_end).diff(dayjs(this.cardData.dt_start), 'minutes');
    }

    get accountBookedTypeClass(): string | null {
        if (this.cardData.booking_platform === undefined) {
            return null;
        }
        if (this.cardData.booking_platform === 'sc') {
            return 'b-plan_table__label__appointment--sc';
        }
        return `b-plan_table__label__appointment--sass`;
    }

    get isUnavailabilityEvent() {
        return this.cardData.origin === 'from_unavailability';
    }

    get isDriveEvent(): boolean {
        return this.cardData.origin === EVENT_ORIGIN_DRIVE;
    }

    get privateEvent() {
        return this.cardData.is_private && this.cardData.user_id !== this.user.id;
    }

    get unavailabilityTitle() {
        return unavailabilityTitleTranslate(this.cardData.title);
    }

    get time(): null | string | TranslateResult {
        if (this.cardData.start_from_morning || this.cardData.fully_day) {
            return null;
        }
        if (this.cardData.full_day) {
            return this.$i18n.t('CALENDAR.FULL.DAY');
        }
        return this.getCurrentDateAsMinutesAMPM(new Date(this.cardData.dt_start_view || this.cardData.dt_start), false);
    }

    get color() {
        const colorsData: Array<string> = this.cardData.color.split(' - ');
        return `linear-gradient(${colorsData[0]}, ${colorsData[1]})`;
    }
}
</script>

<style lang='sass'>
.b-plan_table__label__wrapper--disabled
    display: flex
    justify-content: center
    align-items: center
    pointer-events: none
    height: 100%
    background: rgba(255, 255, 255, 0.6)
    color: $dark-blue
    font-size: 12px
    padding: 4px 7px 4px 7px
    position: relative
    border-radius: 5px
    overflow: hidden

.b-plan_table__label
    height: 100%
    background: linear-gradient(to bottom,  #f9f9f9 0%,#ffffff 12%,#ffffff 87%,#f9f9f9 100%)
    color: $dark-blue
    font-size: 12px
    padding: 4px 7px 4px 12px
    position: relative
    border-radius: 5px
    box-shadow: 0 5px 11px 0 rgba(33,63,107,0.1)
    overflow: hidden

    &--header
        font-weight: 300

    &--header, &__overflow-text
        width: 75px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap

    &--small
        text-transform: lowercase

    &__wrapper
        padding: 0 4px
        height: 100%

        &--small
            .b-plan_table__label
                padding: 0px 7px 0px 12px

    &__before
        left: 0
        width: 5px
        height: 100%
        position: absolute
        top: 0
        border-radius: 5px 0 0 5px

    &__appointment
        right: 0
        width: 8px
        height: 8px
        position: absolute
        bottom: 0
        border-radius: 5px 0 5px 0

        &--sc
            background: #37B4FB !important

        &--saas
            background: #FB7C07 !important

.b-plan_table__transparent
    background: #EEF0F4

.b-reserved-slot-card
    width: 100% !important
    pointer-events: none
    position: absolute !important
    z-index: 1 !important
    background-color: #f0f2f5
    border-radius: 5px
    color: #bec7d3
    font-size: 12px
    font-weight: 500

    &:hover
        background-color: #f0f2f5 !important

    &__title
        text-transform: uppercase
        text-align: center
        padding-top: 5px
</style>
