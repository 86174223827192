<template>
<div class='b-update-drive-time-popup
            b-blue-background
            b-blue-background__opacity
            b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='400px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        padding='20px 30px'
        popupRadius='20px'
        heightValue='100%'
        @close='closePopup'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mb-0 h-text-center'>
                    {{ $t('CALENDAR.UPDATE_DRIVE_TIME') }}
                </h2>
            </div>
            <div class='b-update-drive-time-popup__text'>
                {{ $t('CALENDAR.UPDATE_DRIVE_TIME_TO') }} {{ currentFormattedDate }} ?
            </div>
            <div class='b-update-drive-time-popup__buttons'>
                <FwButton
                    class='h-mt-10 h-flex-1-0 h-mh-10'
                    borderRadiusType='small-border'
                    color='alert'
                    :paddingHorizontal='true'
                    @click='closePopup'>
                    {{ $t('AUTH.LOG_OUT.NO') }}
                </FwButton>

                <FwButton
                    class='h-mt-10 h-flex-1-0 h-mh-10'
                    :paddingHorizontal='true'
                    borderRadiusType='small-border'
                    @click='updateDriveTime'>
                    {{ $t('AUTH.LOG_OUT.YES') }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { BASE_BACK_TIME_FORMAT } from '@/helpers/dates';

@Component
export default class UpdateDriveTimePopup extends Vue {
    @Prop({ type: String, default: null }) readonly driveUpdateTo!: string;

    get currentFormattedDate() {
        return dayjs(this.driveUpdateTo).format(BASE_BACK_TIME_FORMAT);
    }

    updateDriveTime(): void {
        this.$emit('updateDriveTime');
    }

    closePopup(): void {
        this.$emit('close');
    }
}
</script>

<style lang='sass'>
.b-update-drive-time-popup
    &__text
        padding-top: 20px
        font-size: 16px
        padding-bottom: 20px

    &__buttons
        display: flex
</style>
