export default {
    'CALENDAR.WEEK.TITLE': 'Semaine',
    'CALENDAR.MONTH.TITLE': 'Mois',
    'CALENDAR.YEAR.TITLE': 'Année',

    'CALENDAR.BUTTON.TODAY': 'Aujourd\'hui',
    'CALENDAR.VIEW.AGENDA': 'Voir l\'agenda de',
    'CALENDAR.APPOINTMENT.LABEL': 'RDV',
    'CALENDAR.DURATION.LABEL': 'Durée',
    'CALENDAR.LOCATION.LABEL': 'Adresse',
    'CALENDAR.MONTH.JANUARY': 'Janvier',
    'CALENDAR.MONTH.FEBRUARY': 'Fevrier',
    'CALENDAR.MONTH.MARCH': 'Mars',
    'CALENDAR.MONTH.APRIL': 'Avril',
    'CALENDAR.MONTH.MAY': 'Mai',
    'CALENDAR.MONTH.JUNE': 'Juin',
    'CALENDAR.MONTH.JULY': 'Juillet',
    'CALENDAR.MONTH.AUGUST': 'Août',
    'CALENDAR.MONTH.SEPTEMBER': 'Septembre',
    'CALENDAR.MONTH.OCTOBER': 'Octobre',
    'CALENDAR.MONTH.NOVEMBER': 'Novembre',
    'CALENDAR.MONTH.DECEMBER': 'Decembre',
    'CALENDAR.WEEK.MONDAY': 'Lundi',
    'CALENDAR.WEEK.TUESDAY': 'Mardi',
    'CALENDAR.WEEK.WEDNESDAY': 'Mercredi',
    'CALENDAR.WEEK.THURSDAY': 'Jeudi',
    'CALENDAR.WEEK.FRIDAY': 'Vendredi',
    'CALENDAR.WEEK.SATURDAY': 'Samedi',
    'CALENDAR.WEEK.SUNDAY': 'Dimanche',

    'CALENDAR.FULL.DAY': 'Toute la journée',
    'CALENDAR.FULL.DAY.EVENT': 'Événement toute la journée',

    'CALENDAR.TIME.12.AM': '00h00',
    'CALENDAR.TIME.12.15.AM': '00h15',
    'CALENDAR.TIME.12.30.AM': '00h30',
    'CALENDAR.TIME.12.45.AM': '00h45',
    'CALENDAR.TIME.01.AM': '01h00',
    'CALENDAR.TIME.01.15.AM': '01h15',
    'CALENDAR.TIME.01.30.AM': '01h30',
    'CALENDAR.TIME.01.45.AM': '01h45',
    'CALENDAR.TIME.02.AM': '02h00',
    'CALENDAR.TIME.02.15.AM': '02h15',
    'CALENDAR.TIME.02.30.AM': '02h30',
    'CALENDAR.TIME.02.45.AM': '02h45',
    'CALENDAR.TIME.03.AM': '03h00',
    'CALENDAR.TIME.03.15.AM': '03h15',
    'CALENDAR.TIME.03.30.AM': '03h30',
    'CALENDAR.TIME.03.45.AM': '03h45',
    'CALENDAR.TIME.04.AM': '04h00',
    'CALENDAR.TIME.04.15.AM': '04h15',
    'CALENDAR.TIME.04.30.AM': '04h30',
    'CALENDAR.TIME.04.45.AM': '04h45',
    'CALENDAR.TIME.05.AM': '05h00',
    'CALENDAR.TIME.05.15.AM': '05h15',
    'CALENDAR.TIME.05.30.AM': '05h30',
    'CALENDAR.TIME.05.45.AM': '05h45',
    'CALENDAR.TIME.06.AM': '06h00',
    'CALENDAR.TIME.06.15.AM': '06h15',
    'CALENDAR.TIME.06.30.AM': '06h30',
    'CALENDAR.TIME.06.45.AM': '06h45',
    'CALENDAR.TIME.07.AM': '07h00',
    'CALENDAR.TIME.07.15.AM': '07h15',
    'CALENDAR.TIME.07.30.AM': '07h30',
    'CALENDAR.TIME.07.45.AM': '07h45',
    'CALENDAR.TIME.08.AM': '08h00',
    'CALENDAR.TIME.08.15.AM': '08h15',
    'CALENDAR.TIME.08.30.AM': '08h30',
    'CALENDAR.TIME.08.45.AM': '08h45',
    'CALENDAR.TIME.09.AM': '09h00',
    'CALENDAR.TIME.09.15.AM': '09h15',
    'CALENDAR.TIME.09.30.AM': '09h30',
    'CALENDAR.TIME.09.45.AM': '09h45',
    'CALENDAR.TIME.10.AM': '10h00',
    'CALENDAR.TIME.10.15.AM': '10h15',
    'CALENDAR.TIME.10.30.AM': '10h30',
    'CALENDAR.TIME.10.45.AM': '10h45',
    'CALENDAR.TIME.11.AM': '11h00',
    'CALENDAR.TIME.11.15.AM': '11h15',
    'CALENDAR.TIME.11.30.AM': '11h30',
    'CALENDAR.TIME.11.45.AM': '11h45',
    'CALENDAR.TIME.12.PM': '12h00',
    'CALENDAR.TIME.12.15.PM': '12h15',
    'CALENDAR.TIME.12.30.PM': '12h30',
    'CALENDAR.TIME.12.45.PM': '12h45',
    'CALENDAR.TIME.01.PM': '13h00',
    'CALENDAR.TIME.01.15.PM': '13h15',
    'CALENDAR.TIME.01.30.PM': '13h30',
    'CALENDAR.TIME.01.45.PM': '13h45',
    'CALENDAR.TIME.02.PM': '14h00',
    'CALENDAR.TIME.02.15.PM': '14h15',
    'CALENDAR.TIME.02.30.PM': '14h30',
    'CALENDAR.TIME.02.45.PM': '14h45',
    'CALENDAR.TIME.03.PM': '15h00',
    'CALENDAR.TIME.03.15.PM': '15h15',
    'CALENDAR.TIME.03.30.PM': '15h30',
    'CALENDAR.TIME.03.45.PM': '15h45',
    'CALENDAR.TIME.04.PM': '16h00',
    'CALENDAR.TIME.04.15.PM': '16h15',
    'CALENDAR.TIME.04.30.PM': '16h30',
    'CALENDAR.TIME.04.45.PM': '16h45',
    'CALENDAR.TIME.05.PM': '17h00',
    'CALENDAR.TIME.05.15.PM': '17h15',
    'CALENDAR.TIME.05.30.PM': '17h30',
    'CALENDAR.TIME.05.45.PM': '17h45',
    'CALENDAR.TIME.06.PM': '18h00',
    'CALENDAR.TIME.06.15.PM': '18h15',
    'CALENDAR.TIME.06.30.PM': '18h30',
    'CALENDAR.TIME.06.45.PM': '18h45',
    'CALENDAR.TIME.07.PM': '19h00',
    'CALENDAR.TIME.07.15.PM': '19h15',
    'CALENDAR.TIME.07.30.PM': '19h30',
    'CALENDAR.TIME.07.45.PM': '19h45',
    'CALENDAR.TIME.08.PM': '20h00',
    'CALENDAR.TIME.08.15.PM': '20h15',
    'CALENDAR.TIME.08.30.PM': '20h30',
    'CALENDAR.TIME.08.45.PM': '20h45',
    'CALENDAR.TIME.09.PM': '21h00',
    'CALENDAR.TIME.09.15.PM': '21h15',
    'CALENDAR.TIME.09.30.PM': '21h30',
    'CALENDAR.TIME.09.45.PM': '21h45',
    'CALENDAR.TIME.10.PM': '22h00',
    'CALENDAR.TIME.10.15.PM': '22h15',
    'CALENDAR.TIME.10.30.PM': '22h30',
    'CALENDAR.TIME.10.45.PM': '22h45',
    'CALENDAR.TIME.11.PM': '23h00',
    'CALENDAR.TIME.11.15.PM': '23h15',
    'CALENDAR.TIME.11.30.PM': '23h30',
    'CALENDAR.TIME.11.45.PM': '23h45',

    'CALENDAR.DAY.MONDAY.SYMBOL': 'L',
    'CALENDAR.DAY.TUESDAY.SYMBOL': 'M',
    'CALENDAR.DAY.WEDNESDAY.SYMBOL': 'M',
    'CALENDAR.DAY.THURSDAY.SYMBOL': 'J',
    'CALENDAR.DAY.FRIDAY.SYMBOL': 'V',
    'CALENDAR.DAY.SATURDAY.SYMBOL': 'S',
    'CALENDAR.DAY.SUNDAY.SYMBOL': 'D',

    'CALENDAR.ALL.DATES': `TOUTES LES DATES`,
    'CALENDAR.DAY': `JOUR`,
    'CALENDAR.WEEK': `SEMAINE`,
    'CALENDAR.MONTH': `MOIS`,

    'CALENDAR.UPDATE_DRIVE_TIME': `Mettre à jour le temps de trajet`,
    'CALENDAR.UPDATE_DRIVE_TIME_TO': `Souhaitez-vous mettre à jour le temps de trajet à`,
};
