import fecha from 'fecha';
import locale from './locale';
import { EventsList } from '../../components/simple/EventsList';
import { calculateMonthCalendarViewDays } from '../../helpers/table';

export function formatDate(date, format) {
    if (!date) {
        return '';
    }
    try {
        return fecha.format(new Date(date), format);
    } catch (e) {
        return '';
    }
}

export default {
    name: 'PanelDateMonth',
    mixins: [locale],
    props: {
        value: null,
        startAt: null,
        endAt: null,
        dateFormat: {
            type: String,
            default: 'YYYY-MM-DD',
        },
        calendarMonth: {
            type: Number,
            required: true,
        },
        calendarYear: {
            type: Number,
            required: true,
        },
        date: {
            required: true,
            type: Date,
        },
        firstDayOfWeek: {
            default: 7,
            type: Number,
            validator: val => val >= 1 && val <= 7,
        },
        disabledDate: {
            type: Function,
            default: () => {
                return false;
            },
        },
        markedDate: {
            type: Function,
            default: () => {
                return false;
            },
        },
        events: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        selectDate({ year, month, day }) {
            const date = new Date(year, month, day);
            if (this.disabledDate(date)) {
                return;
            }
            if (this.markedDate(date)) {
                this.$emit('clickOnMarkedDate', date);
            }
            this.$emit('select', date);
        },
        getDays(firstDayOfWeek, field) {
            const days = this.t(field);
            const firstday = parseInt(firstDayOfWeek, 10);
            return days.concat(days).slice(firstday, firstday + 7);
        },
        getDates(year, month, firstDayOfWeek) {
            return calculateMonthCalendarViewDays(year, month, firstDayOfWeek);
        },
        getCellClasses({ year, month, day }) {
            const classes = [];
            const cellTime = new Date(year, month, day).getTime();
            const today = new Date().setHours(0, 0, 0, 0);
            const curTime = this.value && new Date(this.value).setHours(0, 0, 0, 0);
            const startTime = this.startAt && new Date(this.startAt).setHours(0, 0, 0, 0);
            const endTime = this.endAt && new Date(this.endAt).setHours(0, 0, 0, 0);

            if (month < this.calendarMonth - 1) {
                classes.push('last-month');
            } else if (month > this.calendarMonth - 1) {
                classes.push('next-month');
            } else {
                classes.push('cur-month');
            }
            if (this.date.getTime() === cellTime) {
                classes.push('current-date');
            }

            if (cellTime === today) {
                classes.push('today');
            }

            if (this.disabledDate(cellTime)) {
                classes.push('disabled');
            }

            if (this.markedDate(cellTime)) {
                classes.push('marked');
            }

            if (curTime) {
                if (cellTime === curTime) {
                    classes.push('actived');
                } else if (startTime && cellTime <= curTime) {
                    classes.push('inrange');
                } else if (endTime && cellTime >= curTime) {
                    classes.push('inrange');
                }
            }
            return classes;
        },
        onOpenEvent(event) {
            this.$emit('openEvent', event);
        },
        getCellTitle({ year, month, day }) {
            return formatDate(new Date(year, month, day), this.dateFormat);
        },
    },
    render() {
        const ths = this.getDays(this.firstDayOfWeek, 'days_full').map(day => {
            return <th class='b-plan-table-month__th'>{day}</th>;
        });

        const dates = this.getDates(this.calendarYear, this.calendarMonth - 1, this.firstDayOfWeek);
        // eslint-disable-next-line prefer-spread
        const tbody = Array.apply(null, { length: 6 }).map((week, i) => {
            const tds = dates.slice(7 * i, 7 * i + 7).map(date => {
                const attrs = {
                    class: this.getCellClasses(date),
                };
                const currentEvents = this.events.filter(item => {
                    const localDate = new Date(item.dt_start);
                    return date.day === localDate.getDate() && localDate.getMonth() === date.month && localDate.getFullYear() === date.year;
                });
                let eventsListComponent;
                if (currentEvents && currentEvents.length) {
                    eventsListComponent = <EventsList events={currentEvents} onOpenEvent={this.onOpenEvent} />;
                }
                return (
                    <td
                        class='cell b-plan-table-month__cell'
                        {...attrs}
                        data-year={date.year}
                        data-month={date.month}
                        title={this.getCellTitle(date)}
                        onClick={this.selectDate.bind(this, date)}>
                        <div class='b-plan-table-month__cell__inner'>
                            <div class='b-plan-table-month__cell__day'>
                                {date.day}
                            </div>
                            {eventsListComponent}
                        </div>
                    </td>
                );
            });
            return <tr>{tds}</tr>;
        });

        return (
            <table class='mx-panel mx-panel-date'>
                <thead>
                    <tr>{ths}</tr>
                </thead>
                <tbody>
                    {tbody}
                </tbody>
            </table>
        );
    },
};
