<template>
<div ref='table'
     class='b-plan-table__wrapper qa-plan-table'
     :class='{ "b-plan-table__wrapper--full-width": isTableFullWidth }'>
    <table class='b-plan-table'
           cellspacing='0'
           cellpadding='0'>
        <colgroup>
            <col width='95'>
            <col v-for='i in 2'
                 :key='i'
                 class='b-plan-table__row_color'>
        </colgroup>
        <thead>
            <tr>
                <td></td>
                <td v-for='(date, index) in dates'
                    :key='`${date.toString()}_${index}`'>
                    <div class='h-p-2'>
                        <div class='b-plan-table_th h-font-12 h-fw-500'
                             :class='{ "b-plan-table_th--active": compareDates(date) }'>
                            {{ $t(getWeekDayMame(date)).substring(0, 3).toUpperCase() }}.
                            <span class='h-ml-5'>
                                {{ getDay(date) }}
                            </span>
                        </div>
                    </div>
                </td>
            </tr>
            <tr v-if='user && user.technician_profile && user.technician_profile.company_admin'>
                <td></td>
                <td v-for='date in dates'
                    :key='date.toString()'>
                    <div class='b-plan-table_th__users'>
                        <div v-for='user in filteredUsers'
                             :key='`${+date}-${user.id}`'
                             :style='getTdInnerStyle(`${+date}-${user.id}`)'
                             class='b-plan-table_th__user'>
                            {{ $t(name(user)) }}

                            <span class='b-plan-table_th__user__label'
                                  :style='{ backgroundColor: user.color }'></span>
                        </div>
                    </div>
                </td>
            </tr>
        </thead>
        <tbody ref='tableBody'>
            <tr class='b-plan-table_header'>
                <td class='b-plan-table__full b-plan-table__time--center b-td-no-border'>
                    <span class='h-pr-10 h-fw-500'>
                        {{ $t('CALENDAR.FULL.DAY') }}
                    </span>
                </td>
                <td v-for='date in dates'
                    :key='date.toString()'>
                    <div class='b-plan-table_th__users b-plan-table_th__users--full-day'>
                        <div v-for='user in filteredUsers'
                             :style='getTdInnerStyle(`${+date}-${user.id}`)'
                             :class='{ "b-plan-table_th__users--full-day-prevent": user.is_site }'
                             class='b-plan-table_td__user'>
                            <div class='b-plan-table__time__full_day b-plan-table_th__whole_day__label'
                                 :style='{
                                     borderTop: `5px solid ${userColor(user)}`,
                                 }'
                                 @click='showAddPopup(user, null, date)'>
                                <div v-for='(cardData, index) in getEventsByDate(date, null, user, true, `${+date}-${user.id}`)'>
                                    <PlanTableCard
                                        :id='`qa-${cardData.id}`'
                                        :key='index'
                                        class='b-plan-table__time-card b-plan-table__time-card--full-day h-width-100p qa-time-card'
                                        :cardData='cardData'
                                        :wrapperHeight='wrapperHeight'
                                        @click.native.stop='editEvent({ data: cardData, user })'>
                                    </PlanTableCard>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody ref='tbody'>
            <tr v-for='data in checkCalendarViewTime'>
                <td class='b-td-no-border'>
                    <div class='b-plan-table__time h-pos-rel h-fw-500 h-font-10'>
                        <div v-if='showLine(data)'
                             :style='showLineStyles'
                             class='b-plan-table__timeline__wrapper'>
                            <div class='b-plan-table__timeline'></div>
                        </div>
                        <template v-if='data.calendarViewTime !== "24:00"'>
                            {{ $t(data.time) }}
                        </template>
                    </div>
                </td>
                <td v-for='(date, indexDates) in dates'
                    :key='indexDates'>
                    <div class='b-plan-table__time-card-wrapper'
                         :class='{ "b-plan-table__time-card-wrapper--last": data.calendarViewTime === "24:00" }'>
                        <div v-for='user in filteredUsers'
                             :key='`${+date}-${user.id}`'
                             class='b-plan-table_td__user'
                             :class='noPointer ? null : `h-pointer`'
                             :style='getTdInnerStyle(`${+date}-${user.id}`, data.time, +date, user.id)'
                             @mouseover='showTimeAppointmentAdd(data.time, +date, user.id, data.calendarViewTime === "24:00")'
                             @mouseleave='hideTimeAppointmentAdd'>
                            <AddAnAppointment
                                v-if='showAddAppointmentZone({ localTime: data.time, timestamp: +date }) && showByIndex === user.id && data.calendarViewTime !== "24:00"'
                                :fristHalfTime='$t(data.time)'
                                :choosedDay='`${date}`'
                                :secondHalfTime='secondHalfTime($t(data.time))'
                                @openAddAppointmentPopup='showAddPopup(user, data.time, date)'>
                            </AddAnAppointment>
                            <template>
                                <PlanTableCard
                                    v-for='(cardData, index) in getEventsByDate(date, data.time, user, false, `${+date}-${user.id}`, data.calendarViewTime === "24:00")'
                                    :id='`qa-${cardData.id}`'
                                    :key='index'
                                    class='b-plan-table__time-card qa-time-card'
                                    :class='{
                                        "b-plan-table__time-card--morning": cardData.start_from_morning,
                                        "b-plan-table__time-card--night": cardData.finish_on_night,
                                        "b-plan-table__time-card--fully": cardData.fully_day,
                                    }'
                                    :style='getStyles(cardData, false)'
                                    :cardData='cardData'
                                    @mouseover.native.stop='forceHideAddZone = true'
                                    @mouseleave.native.stop='forceHideAddZone = false'
                                    @drag='onDrag'
                                    @dragstart='onDragStart'
                                    @dragend='onDragEnd'
                                    @click.native.stop='editEvent({ data: cardData, user })'>
                                </PlanTableCard>
                            </template>
                            <template v-if='driveAvailabilities.length > 0'>
                                <drop
                                    v-for='drop in driveAvailabilitiesByDate(user, date, data)'
                                    :key='drop.dt_start'
                                    :class='{ over: driveOver && drop.id === driveOver.id }'
                                    class='b-drop-area'
                                    tag='div'
                                    :style='getDropStyles(drop, data)'
                                    @dragover='({ card }, event) => onDragOver(drop, event, card)'
                                    @dragleave='onDragLeave'
                                    @drop='handleDrop'>
                                </drop>
                            </template>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <UpdateDriveTimePopup
        v-if='showUpdateDrivePopup'
        :driveUpdateTo='driveUpdateTo'
        @updateDriveTime='updateDriveTime'
        @close='closeUpdateDrivePopup'>
    </UpdateDriveTimePopup>
</div>
</template>

<script lang='ts'>
import { clone, filter, sort, maxBy, uniqBy, values, flatten } from 'ramda';
import dayjs from 'dayjs';
import { Drop } from 'vue-drag-drop';
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import DateMixin from '@/mixins/dateMixin';
import { showTime, mainCalendarTime, time } from '@/mocks/tableData';
import { EventsDataEmptyType, LunchDaysTimeType, whDaysTimeType } from '@/types/Events/Base';
import { DriveAvailabilityType, DriveAvailabilityTypeWithID, EventDataType } from '@/types/Events';
import { userTableDataType, UserType } from '@/types/User';
import { PlanTableCard } from '@/components/simple/PlanTableCard';
import { PlanTableLanchCard } from '@/components/simple/PlanTableLanchCard';
import { UpdateDriveTimePopup } from '@/components/popups/UpdateDriveTimePopup';
import { CalendarInstanceType } from '@/types/Events/CalendarInstanceType';
import { hexToRgbA } from '@/helpers/colors';
import { getUserName } from '@/helpers/translate';
import AccountMixin from '@/mixins/account';
import { AddAnAppointment } from '@/components/simple/AddAnAppointment';
import { moveArrayElement } from '@/helpers/base';
import { EVENT_ORIGIN_DRIVE } from '@/helpers/events';
import { equalByDay, getDateAsIsoString, getNumberFromTime, BASE_BACK_TIME_FORMAT } from '@/helpers/dates';
import i18n from '@/locale';
import { CalendarApi } from '@/api/calendar/CalendarApi';

type instancePeriodType = {
    start: Date
    end: Date
}

@Component({
    components: {
        Drop,
        PlanTableCard,
        AddAnAppointment,
        PlanTableLanchCard,
        UpdateDriveTimePopup,
    },
    refs: [`table`, `tableBody`, `tbody`],
})
export default class PlanTable extends Mixins(DateMixin, AccountMixin) {
    @Prop({ type: Array, required: true }) readonly dates!: Array<Date>;
    @Prop({ type: Array, required: true }) readonly users!: Array<UserType>;
    @Prop({ type: Number, required: true }) readonly updateKey!: number;
    @Prop({ type: Array, required: true }) readonly userFilterIds!: Array<string>;
    @Prop({ type: Array, required: true }) readonly events!: Array<CalendarInstanceType>;
    @Prop({ type: Object, required: true }) readonly lunchBreakMap!: LunchDaysTimeType | any;
    @Prop({ type: Object, required: true }) readonly whBreakMap!: whDaysTimeType | any;

    $refs!: {
        table: HTMLElement
        tableBody: HTMLElement
        tbody: HTMLElement
    };

    timeData: Array<EventDataType> = showTime;
    currentDate: Date = this.getDayDateAtNight();
    forceUpdateKey = 1;
    tableWidthMapping: { [key: string]: number } = {};
    availableTimes: Array<string> = mainCalendarTime.map(localTime => localTime.time);
    viewCoeff: number = 1;
    layerY: number | null = null;
    showByIndex: string | null = null;
    showTimeAddAppointment: string | null = null;
    timestampAddAppointment: number | null = null;
    noPointer: boolean = false;
    draggedCard: null | CalendarInstanceType = null;
    driveForUpdate: null | CalendarInstanceType = null;
    driveUpdateTo: null | string = null;
    showUpdateDrivePopup: boolean = false;
    forceHideAddZone: boolean = false;
    driveOver: null | DriveAvailabilityTypeWithID = null;
    driveAvailabilities: Array<DriveAvailabilityTypeWithID> = [];
    dayNames: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    get wrapperHeight(): null | number {
        return this.$refs.tbody && this.updateKey && this.events && this.userFilterIds ? this.$refs.tbody.offsetHeight : null;
    }

    get filteredUsers(): Array<UserType> {
        const users: Array<UserType> = moveArrayElement(this.users, this.users.findIndex(user => user.id === this.userId), 0, this.user);
        if (this.isAllUsersFilterActive) {
            return users;
        }
        return users.filter(user => this.userFilterIds.includes(user.id));
    }

    get sitesArray() {
        return this.users.filter(user => user.is_site);
    }

    get getLunchByDate() {
        // eslint-disable-next-line
        const result = this.dates.map(day => {
            const [timeSiteStart, timeSiteEnd] = values(this.lunchBreakMap[this.dayNames[day.getDay()]]);
            if (timeSiteStart && timeSiteEnd) {
                return {
                    id: day,
                    title: i18n.tc('TITLE.LUNCH.BREAK'),
                    dt_start: `${this.getPrettyStringDate(new Date(day))} ${timeSiteStart}`,
                    dt_end: `${this.getPrettyStringDate(new Date(day))} ${timeSiteEnd}`,
                    kind: 'room_lunch',
                    full_day: false,
                };
            }
        });
        return result.filter(item => item);
    }

    get isAllUsersFilterActive(): boolean {
        return this.userFilterIds.length === 1 && this.userFilterIds[0] === '0';
    }

    get tableBodyWidth() {
        return this.$refs.tableBody ? this.$refs.tableBody.offsetWidth : null;
    }

    get tableBodyWidthPx(): null | string {
        return this.tableBodyWidth ? `${this.tableBodyWidth}px` : null;
    }

    get showLineStyles() {
        return {
            width: this.tableBodyWidthPx,
            top: `${new Date().getMinutes() / 60 * 100}px`,
        };
    }

    get draggedCardHeight(): number {
        if (!this.draggedCard) {
            return 0;
        }
        return dayjs(this.draggedCard.dt_end).diff(dayjs(this.draggedCard.dt_start), 'minutes');
    }

    get getDropAreaOverStyles() {
        return this.driveOver && this.draggedCard && this.layerY ? {
            top: `${dayjs(this.driveOver.dt_start).minute() + this.layerY}px`,
            height: `${this.draggedCardHeight}px`,
        } : null;
    }

    get isTableFullWidth(): boolean {
        if (this.isAllUsersFilterActive) {
            return this.users.length === 1;
        }
        return this.userFilterIds.length === 1 && this.userFilterIds[0] !== '0';
    }

    get checkCalendarViewTime(): Array<EventDataType> {
        let result: Array<EventDataType> = [];
        const notFullDayEvents = this.events.filter(item => !item.full_day);
        if (notFullDayEvents.length === 0) {
            return showTime;
        }
        const dates: Array<string> = this.dates.map(item => this.getPrettyStringDate(item));
        const filteredEventOnWeek = notFullDayEvents.filter(item => dates.includes(this.getPrettyStringDate(new Date(item.dt_start_view || item.dt_start))));
        if (!filteredEventOnWeek.length) {
            return showTime;
        }
        // @ts-ignore-next-line
        const eventWithMaxHours = filteredEventOnWeek.reduce((prevEvent: CalendarInstanceType, currentEvent: CalendarInstanceType) => {
            const prevDate = new Date(prevEvent.dt_end);
            const currentDate = new Date(currentEvent.dt_end);
            const isPrevMore = (prevDate.getHours() > currentDate.getHours()) ||
                (prevDate.getHours() === currentDate.getHours() && prevDate.getMinutes() > currentDate.getMinutes());
            return isPrevMore ? prevEvent : currentEvent;
        });
        // @ts-ignore-next-line
        const eventWithMinHours = filteredEventOnWeek.reduce((prevEvent: CalendarInstanceType, currentEvent: CalendarInstanceType) => {
            const prevDate = new Date(prevEvent.dt_start);
            const currentDate = new Date(currentEvent.dt_start);
            const isPrevSmaller = (prevDate.getHours() < currentDate.getHours()) ||
                (prevDate.getHours() === currentDate.getHours() && prevDate.getMinutes() < currentDate.getMinutes());
            return isPrevSmaller ? prevEvent : currentEvent;
        });
        const minHour = new Date(eventWithMinHours.dt_start).getHours();
        const maxHour = new Date(eventWithMaxHours.dt_end).getHours();
        const maxMinutes = new Date(eventWithMaxHours.dt_end).getMinutes();
        let coff = maxMinutes ? 60 / maxMinutes : 0;
        const startIndexOfEvent = time.findIndex(item => item.hours === minHour && item.minutes === 0); // because in pro minutes can be any
        // eslint-disable-next-line max-len
        const endIndexOfEvent = time.findIndex(item => item.hours === maxHour + 1 && item.minutes === 0) || time.findIndex(item => item.hours === maxHour && item.minutes === 45); // because in pro minutes can be any
        const startIndexBase = time.findIndex(item => item.hours === showTime[0].hours && item.minutes === showTime[0].minutes);
        const endIndexBase = time.findIndex(item => item.hours === showTime[showTime.length - 1].hours && item.minutes === showTime[showTime.length - 1].minutes);
        const startIndexToSlice = startIndexOfEvent < startIndexBase ? startIndexOfEvent : startIndexBase;
        const endIndexToSlice = endIndexOfEvent > endIndexBase ? endIndexOfEvent : endIndexBase;
        const available = time.slice(startIndexToSlice, endIndexToSlice + coff + 1);
        this.availableTimes = available.map(localTime => localTime.time);
        const isEventInRange: boolean = Boolean(showTime.find(item => item.hours === minHour) && showTime.find(item => item.hours === maxHour + 1));
        // eslint-disable-next-line max-len
        if (isEventInRange || (available.length && this.$i18n.t(available[available.length - 1].time) === this.formatAMPM(new Date(eventWithMaxHours.dt_end), false))) {
            coff = 3;
        }
        const coefficient = coff || 0;
        this.viewCoeff = (this.availableTimes.length - coefficient) / this.availableTimes.length;
        result = available.filter(localTime => localTime.main);
        return result.length ? result : showTime;
    }

    get firstHour(): number {
        return this.checkCalendarViewTime[0] ? this.checkCalendarViewTime[0].hours : 8;
    }

    driveAvailabilitiesByDate(user: UserType, date: Date, data: EventDataType): Array<DriveAvailabilityType> {
        if (!this.draggedCard || user.id !== this.draggedCard.user_id || !dayjs(date).isSame(this.draggedCard.dt_start, 'day')) return [];
        return this.driveAvailabilities.filter(item => {
            if (dayjs(item.dt_start).hour() < this.firstHour && data.hours === this.firstHour) {
                return (
                    this.draggedCard &&
                    (
                        dayjs(this.draggedCard.dt_start).isAfter(dayjs(item.dt_end)) ||
                        dayjs(this.draggedCard.dt_start).isSame(dayjs(item.dt_end), 'minute')
                    )
                );
            }
            return dayjs(item.dt_start).hour() === data.hours;
        });
    }

    async onDragStart({ card }: { card: CalendarInstanceType }) {
        if (!this.isMatmutAccount || new Date() > new Date(card.dt_start) || card.origin !== EVENT_ORIGIN_DRIVE) {
            return;
        }
        const data = await CalendarApi.getDriveAvailabilities({
            advisor_id: card.user_id,
            dt: card.dt_start,
            drive_id: card.event_id,
            direction: card.location === 'drive_to' ? 'to' : 'from',
        });
        this.driveAvailabilities = data.map(item => ({ id: `${item.dt_start}_${item.dt_end}`, ...item }));
    }

    async onDragEnd() {
        this.driveAvailabilities = [];
    }

    closeUpdateDrivePopup(): void {
        this.showUpdateDrivePopup = false;
        this.driveUpdateTo = null;
    }

    onDrag({ card }: { card: CalendarInstanceType }) {
        this.draggedCard = card;
    }

    handleChildDragstart($event: any) {
        $event.stopPropagation();
    }

    handleDrop({ card }: { card: CalendarInstanceType }) {
        if (this.driveOver && typeof this.layerY === 'number') {
            const minutes = dayjs(this.driveOver.dt_start).minute();
            const hours = dayjs(this.driveOver.dt_start).hour();
            if (dayjs(this.driveOver.dt_start).hour() < this.firstHour) {
                this.driveUpdateTo = dayjs(this.driveOver.dt_end).add(-this.draggedCardHeight, 'minutes').locale('en').format(BASE_BACK_TIME_FORMAT);
            } else {
                this.driveUpdateTo = dayjs(this.driveOver.dt_start)
                    .locale('en')
                    .hour(hours + Math.floor(this.layerY / 60))
                    .minute(minutes + this.layerY % 60).format(BASE_BACK_TIME_FORMAT);
            }
            if (card.dt_start !== this.driveUpdateTo) {
                this.driveForUpdate = clone(this.draggedCard);
                this.showUpdateDrivePopup = true;
            }
        }
        this.driveOver = null;
        this.layerY = null;
    }

    calculateHeight(card: CalendarInstanceType | DriveAvailabilityTypeWithID) {
        return dayjs(card.dt_end).diff(dayjs(card.dt_start), 'minutes');
    }

    onDragOver(drop: DriveAvailabilityTypeWithID, event: DragEvent, card: CalendarInstanceType) {
        // @ts-ignore-next-line
        const value = Math.floor(event.layerY / 15) * 15;
        const height = this.calculateHeight(drop);
        const cardHeight = this.calculateHeight(card);
        if (value === this.layerY) return;
        this.layerY = value + cardHeight > height ? height : value;
        this.driveOver = drop;
    }

    onDragLeave() {
        this.driveOver = null;
        this.layerY = null;
    }

    async updateDriveTime() {
        if (!this.driveForUpdate || !this.driveUpdateTo) {
            return;
        }
        this.showUpdateDrivePopup = false;
        const diff = dayjs(this.driveForUpdate.dt_end).diff(dayjs(this.driveForUpdate.dt_start), 'minutes');
        try {
            const dt_end = dayjs(this.driveUpdateTo).locale('en').add(diff, 'minutes').format(BASE_BACK_TIME_FORMAT);
            await CalendarApi.updateDriveTime({
                id: this.driveForUpdate.event_id,
                dt_start: this.driveUpdateTo,
                dt_end,
            });
            this.$emit('updateAllData');
        } catch (e) {
            console.log(e);
        } finally {
            this.driveForUpdate = null;
            this.showUpdateDrivePopup = false;
            this.driveUpdateTo = null;
        }
    }

    name(user: UserType): string {
        return getUserName(user, this.userId);
    }

    getTdInnerStyle(key: string, timeAddAppointment?: string, timestamp?: number, id?: string) {
        return {
            minWidth: `${(this.tableWidthMapping[key] || 1) * 95}px`,
            ...this.disabledHideTimeSlots(timeAddAppointment as string, timestamp as number, id as string) ? { background: 'rgba(248,249,251,0.3)' } : {},
        };
    }

    userColor(user: UserType): string {
        const colorGradient = user.color ? user.color : '#B6B6B6 - #A19F9F';
        return hexToRgbA(colorGradient.split(' - ')[0] || colorGradient, 0.8);
    }

    secondHalfTime(localTime: string) {
        const hoursData = this.getHoursAndMinutesFromAMPM(localTime);
        const date = new Date();
        date.setHours(hoursData.hours, 30, 0, 0);
        return this.formatAMPM(date, false);
    }

    getDropStyles(drop: CalendarInstanceType, data: any) {
        let height = dayjs(drop.dt_end).diff(dayjs(drop.dt_start), 'minutes');
        const color = `#DFFAF5`;
        const dropMainColor = `#27dbbd`;
        let top: string | number = 0;
        let bottom: string | number = 'auto';
        const isBefore = dayjs(drop.dt_start).hour() < this.firstHour;
        if (isBefore) {
            top = 'auto';
            bottom = -(dayjs(drop.dt_end).hour() - data.hours - 1) * 60;
            height = (dayjs(drop.dt_end).hour() - this.firstHour + 1) * 60;
        } else {
            top = dayjs(drop.dt_start).minute();
        }
        const topLine = (this.layerY || 0);
        const hoverCurrentCard = typeof this.layerY === 'number' && this.driveOver && drop.id === this.driveOver.id;
        let background = '#fff';
        if (isBefore && hoverCurrentCard) {
            background = dropMainColor;
        } else {
            background = hoverCurrentCard ? `linear-gradient(to bottom,
                    ${color} ${topLine}px,
                    ${dropMainColor} ${topLine}px,
                    ${dropMainColor} ${topLine + this.draggedCardHeight}px,
                    ${color} ${topLine + this.draggedCardHeight}px,
                    ${color} ${height}px
                )` : color;
        }

        return {
            position: 'absolute',
            height: `${height}px`,
            top: typeof top === `number` ? `${top}px` : top,
            bottom: typeof bottom === `number` ? `${bottom}px` : bottom,
            width: '100%',
            background,
            border: `1px solid #fff`,
            zIndex: `1`,
        };
    }

    getStyles(data: CalendarInstanceType, isFullDay: boolean = false) {
        if (isFullDay || !this.wrapperHeight) {
            return {
                height: `40px`,
            };
        }
        let height;
        if (data.start_from_morning) {
            const startViewMinutes: number = this.checkCalendarViewTime.length ? this.checkCalendarViewTime[0].hours * 60 : 0;
            const endMinutes = new Date(data.dt_end).getHours() * 60 + new Date(data.dt_end).getMinutes();
            const eventMinutesLength = (endMinutes - startViewMinutes) / 60;
            height = `${eventMinutesLength}px`;
        } else {
            const eventMinutesLength = Math.floor((Math.abs(+new Date(data.dt_start) - +new Date(data.dt_end)) / 1000) / 60);
            height = `${eventMinutesLength}px`;
        }
        let padding = null;
        if (data.start_from_morning) {
            padding = '0 4px 4px';
        } else if (data.fully_day) {
            padding = '0 4px';
        }
        return {
            height,
            padding,
        };
    }

    calculateIntersection(instances: Array<CalendarInstanceType>): Array<Array<CalendarInstanceType>> {
        const getIntersectionData = (
            { events, periodInstance }: { events: Array<CalendarInstanceType>, periodInstance: CalendarInstanceType }
        ): Array<CalendarInstanceType> => {
            if (!events.length) return [];
            const intersectionData: Array<CalendarInstanceType> = [];
            let instancePeriod: instancePeriodType = {
                start: new Date(periodInstance.dt_start_view || periodInstance.dt_start),
                end: new Date(periodInstance.dt_end_view || periodInstance.dt_end),
            };
            const recCalc = () => {
                const predicate = (instance: CalendarInstanceType) => {
                    const startDate = new Date(instance.dt_start);
                    if (+startDate >= +instancePeriod.start && +startDate < +instancePeriod.end) {
                        const maxEnd = +new Date(instance.dt_end) >= +instancePeriod.end ? new Date(instance.dt_end) : instancePeriod.end;
                        instancePeriod = Object.assign(instancePeriod, { end: maxEnd });
                        return true;
                    }
                    return false;
                };
                const localEvents = events.filter(
                    // @ts-ignore-next-line
                    (instance: CalendarInstanceType) => !intersectionData.some(local => local.id === instance.id)
                );
                const element = localEvents.slice(0, 1);
                const filteredEvents = localEvents.filter(predicate);
                if (!filteredEvents.length) {
                    return element;
                }
                const preparedEvents: Array<CalendarInstanceType> = filteredEvents;
                if (preparedEvents.length) {
                    // @ts-ignore-next-line
                    const maxDtEnd = maxBy((instance: CalendarInstanceType) => +new Date(instance.dt_end), instancePeriod.end, preparedEvents);
                    instancePeriod = Object.assign(instancePeriod, { end: maxDtEnd });
                    intersectionData.push(...preparedEvents);
                    recCalc();
                }
                return intersectionData;
            };
            return recCalc();
        };

        const instancesIntersection: Array<Array<CalendarInstanceType>> = [];
        for (let i = 0; i < instances.length; i++) {
            const instance: CalendarInstanceType = instances[i];
            // @ts-ignore-next-line
            const flatIntersections = flatten(instancesIntersection);
            if (flatIntersections.length) {
                if (flatIntersections.some((local: CalendarInstanceType) => local.id === instance.id)) continue;
            }
            // @ts-ignore-next-line
            const excludeInstanced = uniqBy((item: CalendarInstanceType) => item.id, flatten(instancesIntersection));
            const filteredInstances = instances.filter(
                // @ts-ignore-next-line
                (localInstance: CalendarInstanceType) => !excludeInstanced.some(local => local.id === localInstance.id)
            );
            const intersection = getIntersectionData({ events: filteredInstances, periodInstance: instance });
            instancesIntersection.push(intersection);
        }
        return this.optimizeInstances(instancesIntersection);
    }

    optimizeInstances(data: Array<Array<CalendarInstanceType>>): Array<Array<CalendarInstanceType>> {
        const result: Array<Array<CalendarInstanceType>> = [];
        for (let i = 0; i < data.length; i++) {
            let index: number = 0;
            const group: Array<CalendarInstanceType> = data[i];
            const firstElement = group[index];
            if (!firstElement) continue;
            let partOfEvents: Array<CalendarInstanceType> = [];
            for (let k = 0; k < group.length; k++) {
                const currentEvent = group[index];
                const event = group[k];
                if (+new Date(event.dt_start) < +new Date(currentEvent.dt_end)) {
                    partOfEvents.push(event);
                } else {
                    result.push(partOfEvents);
                    partOfEvents = [];
                    partOfEvents.push(event);
                    index = k;
                }
            }
            result.push(partOfEvents);
        }
        return result;
    }

    // eslint-disable-next-line
    getEventsByDate(date: Date, time: string | null, user: UserType, isFullDay = false, rowKey: string, ignoreElement: boolean = false ): Array<CalendarInstanceType> {
        const eventsByDate = filter((event: CalendarInstanceType) => {
            return event.full_day === isFullDay && equalByDay(new Date(event.dt_start_view || event.dt_start), date);
        }, [...this.events, ...this.getLunchByDate] as Array<CalendarInstanceType>);
        const eventForUser = filter((event: CalendarInstanceType) => event.user_id === user.id, eventsByDate);
        const eventForRooms = filter((event: CalendarInstanceType) => event.site_id === user.id, eventsByDate);
        const lunchForRooms = filter((event: CalendarInstanceType) => user.is_site as boolean && event.kind === 'room_lunch', eventsByDate);
        const sortedEvents = sort((instanceA: CalendarInstanceType, instanceB : CalendarInstanceType) => {
            return +new Date(instanceA.dt_start) - +new Date(instanceB.dt_start);
        }, [...eventForUser, ...eventForRooms, ...lunchForRooms]);
        const instancesIntersection: Array<Array<CalendarInstanceType>> = this.calculateIntersection(sortedEvents);
        const isoDateString = getDateAsIsoString(date);
        const instances: Array<CalendarInstanceType> = [];
        for (let i = 0; i < instancesIntersection.length; i++) {
            const group = instancesIntersection[i];
            if (!group.length) continue;
            const groupStartInstance = group[0];
            // TEMP
            const currentDate = new Date(groupStartInstance.dt_start_view || groupStartInstance.dt_start);
            let isActualTime;
            // full_day only
            if (time === null) {
                isActualTime = groupStartInstance.full_day && getDateAsIsoString(currentDate) === isoDateString;
            } else if (groupStartInstance.start_from_morning) {
                isActualTime = (
                    this.getDateAsIsoString(currentDate) === isoDateString &&
                    this.checkCalendarViewTime.findIndex((item: EventDataType) => item.time === time) === 0
                );
            } else {
                isActualTime = (
                    this.getCurrentDateAsHoursAMPM(currentDate) === this.$i18n.t(time || ``) &&
                    this.getDateAsIsoString(currentDate) === isoDateString
                );
            }
            if (isActualTime && !ignoreElement) {
                instances.push(...group);
            }
        }

        if (rowKey && (instances.length && this.tableWidthMapping[rowKey] < instances.length) || !this.tableWidthMapping[rowKey]) {
            this.$set(this.tableWidthMapping, rowKey, instances.length);
        }

        return instances;
    }

    showLine(eventDataType: EventDataType, date: Date) {
        return (
            this.forceUpdateKey &&
            this.getCurrentDateAsHoursAMPM(new Date()) === this.$i18n.t(eventDataType.time) &&
            +this.currentDate === +date
        );
    }

    disabledHideTimeSlots(timeAddAppointment: string, timestamp: number, id: string) {
        const currentTimeSlot = getNumberFromTime(`${this.getHoursAndMinutesFromAMPM(i18n.tc(timeAddAppointment)).hours}:00:00`) || 0;
        const lunchStart = getNumberFromTime(values(this.lunchBreakMap[this.dayNames[new Date(timestamp).getDay()]])[0]) || 0;
        const lunchEnd = getNumberFromTime(values(this.lunchBreakMap[this.dayNames[new Date(timestamp).getDay()]])[1]) || 0;
        const whStart = getNumberFromTime(values(this.whBreakMap[this.dayNames[new Date(timestamp).getDay()]])[0]) || 0;
        const whEnd = getNumberFromTime(values(this.whBreakMap[this.dayNames[new Date(timestamp).getDay()]])[1]) || 0;

        if (this.sitesArray.some(site => site.id === id) &&
            ((currentTimeSlot >= lunchStart &&
                currentTimeSlot < lunchEnd) ||
                currentTimeSlot < whStart ||
                currentTimeSlot >= whEnd)) {
            this.noPointer = true;
            return true;
        }

        return false;
    }

    showTimeAppointmentAdd(timeAddAppointment: string, timestamp: number, id: string, ignore: boolean = false) {
        if (ignore || this.disabledHideTimeSlots(timeAddAppointment, timestamp, id)) {
            return;
        }
        this.noPointer = false;
        if (id) {
            this.showByIndex = id;
        }
        this.showTimeAddAppointment = timeAddAppointment;
        this.timestampAddAppointment = timestamp;
    }

    hideTimeAppointmentAdd() {
        this.showTimeAddAppointment = null;
        this.timestampAddAppointment = null;
    }

    showAddAppointmentZone({ localTime, timestamp }: { localTime: string, timestamp: number }) {
        if (this.forceHideAddZone) {
            return false;
        }
        const hoursData = this.getHoursAndMinutesFromAMPM(this.$i18n.tc(localTime));
        const calendarDate = new Date(timestamp);
        calendarDate.setHours(hoursData.hours, hoursData.minutes, 0, 0);
        return (
            this.showTimeAddAppointment === localTime &&
            this.timestampAddAppointment === timestamp &&
            +new Date() < +calendarDate
        );
    }

    editEvent({ data, user }: { data: CalendarInstanceType, user: userTableDataType }) {
        this.handleOpenChoosePopup({ data, user });
    }

    showAddPopup(user: userTableDataType, timeString: string | null, localDate: Date) {
        let date: Date;
        if (timeString) {
            const ampm: TranslateResult = this.$i18n.t(timeString);
            const timeData = this.getHoursAndMinutesFromAMPM(ampm);
            date = new Date(localDate.setHours(timeData.hours, timeData.minutes, 0, 0));
        } else {
            date = new Date(localDate.setHours(0, 0, 0, 0));
        }
        this.handleOpenChoosePopup({ user, date });
    }

    compareDates(date: Date) {
        return date.getTime() === this.currentDate.getTime();
    }

    async created() {
        setInterval(() => {
            this.forceUpdateKey += 1;
        }, 1000 * 60);
    }

    @Emit('openChoosePopup')
    handleOpenChoosePopup(
        { data, user, date }: { data?: CalendarInstanceType | EventsDataEmptyType | null, user: userTableDataType, date?: Date }
    ) {
        return { data, user, date };
    }
}
</script>

<style lang='sass'>
$cell-width: 95px
$time-cell-width: 90px
$cell-height: 60px

.b-plan-table
    padding: 0 60px 0 0
    min-width: 100%
    -webkit-transition: opacity .2s
    transition: opacity .2s
    opacity: 1
    &_header
        td:first-child
            background: #fff
        td
            position: sticky
            top: 54px
            z-index: 5
    &__wrapper
        width: calc(100vw)
        position: relative
        padding-bottom: 50px

        &--full-width
            min-width: 100%

            .b-plan-table
                min-width: 100%

            .b-plan-table_td__user, .b-plan-table_th__whole_day
                width: 100%

            .b-plan-table_th__user
                width: 100%

    &_th
        display: flex
        align-items: center
        justify-content: center
        position: relative
        width: 100%
        height: 40px
        border-radius: 3px
        background-color: $light-gray

        &--active
            color: $main-green
            font-weight: bold
            background-color: lighten($main-green, 46)

        &__users
            width: 100%
            display: flex

            &--full-day
                &-prevent.b-plan-table_td__user
                    pointer-events: none
                    background: #fff
                .b-plan-table_td__user
                    height: auto
                    min-height: 51px
                    border-bottom: 4px solid #fff

        &__user
            align-items: center
            display: flex
            justify-content: center
            margin: 0 2px
            padding: 5px 0 5px
            font-size: 12px
            font-weight: 500
            color: $dark-blue
            position: relative

            &__label
                width: 100%
                display: block
                height: 4px
                position: absolute
                bottom: 0
                left: 0
                border-radius: 4px 4px 0 0

        &__whole_day
            background-color: #f8f9fb
            width: $cell-width
            min-width: $cell-width
            flex: 1 0
            margin: 0 2px
            padding: 5px 2px

            &__label
                width: 100%
                color: $dark-blue
                font-size: 10px
                font-weight: 500
                display: flex
                align-items: center
                justify-content: center
                position: relative
                border-radius: 5px
                &:empty:hover
                    background-color: #f0fdfa

                &__before
                    left: 0
                    width: 5px
                    height: 100%
                    position: absolute
                    top: 0
                    border-radius: 5px 0 0 5px

    &_td__user
        background-color: #f8f9fb
        height: 100%
        margin: 0 2px
        min-width: $cell-width
        display: inline-flex

        &:empty:hover
            background-color: lighten($main-green, 46)

    thead
        tr:first-child>td
            position: sticky
            top: 0
            z-index: 5
        td
            padding: 5px 0 5px
            background: #fff

    &__time
        color: rgba(33,63,107, 0.3)
        word-spacing: -4px
        height: $cell-height
        padding: 0 10px
        font-size: 10px
        white-space: nowrap
        display: flex
        justify-content: flex-end
        width: $time-cell-width
        &--center
            align-items: center
            padding: 0 !important
    &__full
        color: rgba(33,63,107, 0.3)
        height: $cell-height
        font-size: 10px
        display: flex
        justify-content: flex-end
        width: $time-cell-width
        word-spacing: normal
        white-space: initial
        span
            text-align: right

    &__time-card-wrapper
        height: $cell-height
        display: flex
        align-items: flex-start
        width: 100%

        &--last
            .b-plan-table_td__user
                background-color: #fff !important
                cursor: default !important

    &__time-card
        cursor: pointer
        position: relative
        z-index: 4
        width: $cell-width
        flex: 1 0

        &--full-day
            height: 40px

        &--morning
            .b-plan_table__label
                border-radius: 0 0 5px 5px
            .b-plan_table__label__before
                border-radius: 0 0 0 5px

        &--night
            .b-plan_table__label
                border-radius: 5px 5px 0 0

            .b-plan_table__label__before
                border-radius: 5px 0 0 0

        &--fully
            .b-plan_table__label
                border-radius: 0
            .b-plan_table__label__before
                border-radius: 0

        &:hover
            background-color: lighten($main-green, 46)

    .b-plan-table_td__user
        position: relative

@include media('>tablet')
    .b-plan-table
        &__wrapper
            width: 100%

.b-plan-table__timeline
    width: 100%
    height: 1px
    background-color: $cancel-red
    position: relative
    margin-left: $time-cell-width

    &:before
        content: ''
        width: 8px
        height: 8px
        left: -4px
        border-radius: 50%
        top: -4px
        background-color: $cancel-red
        position: absolute

    &__wrapper
        width: 100%
        position: absolute
        left: 8px
        z-index: 5

.b-td-no-border
    border: none !important

.drop.over, .b-drop-area.over
    border-color: #aaa
    background: #ccc

    .drag
        padding-top: 10px

        > .drag
            padding: 10px
            background: rgba(0,0,0,0.3)
            width: auto
            height: auto

.b-drop-area
    background-color: rgba(39,214,189,0.2)
    border: 2px dashed #3bd6bd !important
    width: 100%
    z-index: 5 !important

    &--over
        background-color: red
        width: 100%
        height: 30px
        position: absolute
        z-index: 5
</style>
