import { User } from '@/types/User';
import { getUserFromStorage } from '@/helpers/auth';
import { EN_LOCAL } from '@/helpers/locale';
import i18n from '@/locale';

const MONTHS: Array<string> = [
    'CALENDAR.MONTH.JANUARY',
    'CALENDAR.MONTH.FEBRUARY',
    'CALENDAR.MONTH.MARCH',
    'CALENDAR.MONTH.APRIL',
    'CALENDAR.MONTH.MAY',
    'CALENDAR.MONTH.JUNE',
    'CALENDAR.MONTH.JULY',
    'CALENDAR.MONTH.AUGUST',
    'CALENDAR.MONTH.SEPTEMBER',
    'CALENDAR.MONTH.OCTOBER',
    'CALENDAR.MONTH.NOVEMBER',
    'CALENDAR.MONTH.DECEMBER',
];

const WEEK_DAYS: Array<string> = [
    'CALENDAR.WEEK.SUNDAY',
    'CALENDAR.WEEK.MONDAY',
    'CALENDAR.WEEK.TUESDAY',
    'CALENDAR.WEEK.WEDNESDAY',
    'CALENDAR.WEEK.THURSDAY',
    'CALENDAR.WEEK.FRIDAY',
    'CALENDAR.WEEK.SATURDAY',
];

const MORNING_TIME: Array<string> = [
    'CALENDAR.TIME.08.AM',
    'CALENDAR.TIME.08.15.AM',
    'CALENDAR.TIME.08.30.AM',
    'CALENDAR.TIME.08.45.AM',
    'CALENDAR.TIME.09.AM',
    'CALENDAR.TIME.09.15.AM',
    'CALENDAR.TIME.09.30.AM',
    'CALENDAR.TIME.09.45.AM',
    'CALENDAR.TIME.10.AM',
    'CALENDAR.TIME.10.15.AM',
    'CALENDAR.TIME.10.30.AM',
    'CALENDAR.TIME.10.45.AM',
    'CALENDAR.TIME.11.AM',
    'CALENDAR.TIME.11.15.AM',
    'CALENDAR.TIME.11.30.AM',
    'CALENDAR.TIME.11.45.AM',
    'CALENDAR.TIME.12.PM',
];

const AFTERNOON_TIME: Array<string> = [
    'CALENDAR.TIME.02.PM',
    'CALENDAR.TIME.02.15.PM',
    'CALENDAR.TIME.02.30.PM',
    'CALENDAR.TIME.02.45.PM',
    'CALENDAR.TIME.03.PM',
    'CALENDAR.TIME.03.15.PM',
    'CALENDAR.TIME.03.30.PM',
    'CALENDAR.TIME.03.45.PM',
    'CALENDAR.TIME.04.PM',
    'CALENDAR.TIME.04.15.PM',
    'CALENDAR.TIME.04.30.PM',
    'CALENDAR.TIME.04.45.PM',
    'CALENDAR.TIME.05.PM',
    'CALENDAR.TIME.05.15.PM',
    'CALENDAR.TIME.05.30.PM',
    'CALENDAR.TIME.05.45.PM',
    'CALENDAR.TIME.06.PM',
    'CALENDAR.TIME.06.15.PM',
    'CALENDAR.TIME.06.30.PM',
    'CALENDAR.TIME.06.45.PM',
    'CALENDAR.TIME.07.PM',
];

const FULL_TIME = [
    ...MORNING_TIME,
    ...[
        'CALENDAR.TIME.12.15.PM',
        'CALENDAR.TIME.12.30.PM',
        'CALENDAR.TIME.12.45.PM',
        'CALENDAR.TIME.01.PM',
        'CALENDAR.TIME.01.15.PM',
        'CALENDAR.TIME.01.30.PM',
        'CALENDAR.TIME.01.45.PM',
    ],
    ...AFTERNOON_TIME,
];

function dateAsEventTime(date: Date): string {
    const user: null | User = getUserFromStorage();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    let userLocale: string = 'en';
    if (user) {
        userLocale = i18n.locale;
    }
    // @ts-ignore-next-line
    return date.toLocaleDateString(userLocale, options);
}

function getDayOfWeek(date: Date): string {
    return new Date(date).toLocaleString('en-us', { weekday: 'long' });
}

function getDateAsIsoString(date: Date, withOutDate: boolean = false, useUTC = false): string {
    const month = useUTC ? date.getUTCMonth() : date.getMonth();
    const currentMonth: number = month + 1;
    let currentMonthString: string = currentMonth.toString();
    if (currentMonthString.length === 1) {
        currentMonthString = `0${currentMonthString}`;
    }
    const day = withOutDate ? '' : `-${useUTC ? date.getUTCDate() : date.getDate()}`;
    return `${useUTC ? date.getUTCFullYear() : date.getFullYear()}-${currentMonthString}${day}`;
}

function getDateWithDelayInDays(date: Date, delay: number): Date {
    return new Date(date.setDate(date.getDate() + delay));
}

function getDateWithDelayInYears(date: Date, delay: number): Date {
    const localDate = date || new Date();
    return new Date(localDate.setFullYear(localDate.getFullYear() + delay));
}

function getPrettyStringDate(date: Date, i18nLocal: any): string {
    // example: Friday, October 25, 2019
    return `${i18nLocal.t(WEEK_DAYS[date.getDay()])}, ${i18nLocal.t(MONTHS[date.getMonth()])} ${date.getDate()}, ${date.getFullYear()}`;
}

function formatDateForTitle(date: Date): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

function getMinutesOrHoursAsString(value: number) {
    return `${value}`.length === 1 ? `0${value}` : `${value}`;
}

function formatAMPM({ date, locale, useUTC = true }: { date: Date, locale: string, useUTC: boolean }) {
    let hours = useUTC ? date.getUTCHours() : date.getHours();
    let minutes = date.getMinutes();
    if (locale === EN_LOCAL) {
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12;
        if (minutes === 59) {
            minutes = 0;
            hours = hours === 12 ? 12 : hours + 1;
        }
        return `${getMinutesOrHoursAsString(hours)}:${getMinutesOrHoursAsString(minutes)} ${ampm}`;
    }
    if (minutes === 59) {
        minutes = 0;
        hours = hours === 24 ? 24 : hours + 1;
    }
    return `${getMinutesOrHoursAsString(hours)}h${getMinutesOrHoursAsString(minutes)}`;
}

function getCurrentDateAsHoursAMPM({ date, locale, useUTC = true }: { date?: Date | null, locale: string, useUTC?: boolean }) {
    const localDate = date || new Date();
    let hours = useUTC ? localDate.getUTCHours() : localDate.getHours();
    if (locale === EN_LOCAL) {
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12;
        return `${getMinutesOrHoursAsString(hours)}:00 ${ampm}`;
    }
    return `${getMinutesOrHoursAsString(hours)}h00`;
}

// ex: '02:00:00' -> 120
function getNumberFromTime(time: string) {
    return time ? time.split(':').reduce((seconds, value) => {
        return +value + seconds * 60;
    }, 0) / 60 : null;
}

function currentHoursCalculation(calculationNumber: number, locale: string) {
    const localDate = new Date();
    let hours = localDate.getHours() + calculationNumber;
    if (locale === EN_LOCAL) {
        const ampm = hours >= 12 && hours < 24 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12; // the hour '0' should be '12'
        return `${getMinutesOrHoursAsString(hours)}:00 ${ampm}`;
    }
    return `${getMinutesOrHoursAsString(hours)}h00`;
}

function getCurrentDateAsMinutesAMPM({ date, locale, useUTC = false }: { date: Date | null, locale: string, useUTC?: boolean }) {
    const localDate = date || new Date();
    let hours = useUTC ? localDate.getUTCHours() : localDate.getHours();
    const minutes = localDate.getMinutes();
    if (locale === EN_LOCAL) {
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours || 12;
        return `${getMinutesOrHoursAsString(hours)}:${getMinutesOrHoursAsString(minutes)} ${ampm}`;
    }
    return `${getMinutesOrHoursAsString(hours)}h${getMinutesOrHoursAsString(minutes)}`;
}

function getFirstDayOfMonth(date: Date | null) {
    const localDate = date || new Date();
    return new Date(localDate.getFullYear(), localDate.getMonth(), 1);
}

function getLastDayOfMonth(date: Date | null, delay = 0) {
    const localDate = date || new Date();
    return new Date(localDate.getFullYear(), localDate.getMonth() + 1, delay);
}

function getWeekNumber(date: Date) {
    const localDate: Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    localDate.setUTCDate(localDate.getUTCDate() + 4 - (localDate.getUTCDay() || 7));
    const yearStart: Date = new Date(Date.UTC(localDate.getUTCFullYear(), 0, 1));
    return Math.ceil((((+localDate - +yearStart) / 86400000) + 1) / 7);
}

function getHoursAndMinutesFromAMPM(ampm: string): { hours: number, minutes: number } {
    // 03:00 am || 03:00 pm for En
    // 8h00 || 13h00 Fr
    if (ampm.includes('h')) {
        const timeDate: Array<string> = ampm.split('h');
        return {
            hours: parseInt(timeDate[0], 10),
            minutes: parseInt(timeDate[1], 10),
        };
    } else if (ampm.includes(':')) {
        const timeDate: Array<string> = ampm.split(':');
        const isPm = ampm.includes('pm');
        let hours = parseInt(timeDate[0], 10);
        if (isPm && hours < 12) hours += 12;
        if (!isPm && hours === 12) hours -= 12;
        return {
            hours,
            minutes: parseInt(timeDate[1], 10),
        };
    }

    return {
        hours: 0,
        minutes: 0,
    };
}

function dateToString(date: Date, nightTime: boolean = false): string {
    const day = `${date.getFullYear()}-${getMinutesOrHoursAsString(date.getMonth() + 1)}-${getMinutesOrHoursAsString(date.getDate())}`;
    const time = nightTime ? '00:00:00' : `${date.toTimeString().slice(0, 8)}`;
    return `${day}T${time}.000Z`;
}

function parseToUTCDate(string: string) {
    const date = new Date(string);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const hour = date.getUTCHours();
    const minute = date.getUTCMinutes();
    const day = date.getUTCDate();
    return new Date(year, month, day, hour, minute);
}

function equalByDay(date: Date, date2: Date): boolean {
    return (
        date.getDate() === date2.getDate() &&
        date.getMonth() === date2.getMonth() &&
        date.getFullYear() === date2.getFullYear()
    );
}

function getDayDateAtNight(date?: Date): Date {
    const localDate = date || new Date();
    localDate.setHours(0, 0, 0, 0);
    return localDate;
}

function getDate({ delta = 0, date }: { delta: number, date: Date | null }): Date {
    const localDate = date ? new Date(date.toString()) : new Date();
    localDate.setDate(localDate.getDate() + delta);
    return localDate;
}

const BASE_BACK_TIME_FORMAT = 'ddd, DD MMM YYYY HH:mm:ss';

export {
    MONTHS,
    WEEK_DAYS,
    MORNING_TIME,
    AFTERNOON_TIME,
    FULL_TIME,
    dateAsEventTime,
    getDateAsIsoString,
    getWeekNumber,
    getDateWithDelayInDays,
    getDateWithDelayInYears,
    getPrettyStringDate,
    formatDateForTitle,
    formatAMPM,
    currentHoursCalculation,
    getCurrentDateAsHoursAMPM,
    getCurrentDateAsMinutesAMPM,
    getHoursAndMinutesFromAMPM,
    getFirstDayOfMonth,
    getLastDayOfMonth,
    getMinutesOrHoursAsString,
    dateToString,
    equalByDay,
    getDayDateAtNight,
    getDate,
    parseToUTCDate,
    getNumberFromTime,
    BASE_BACK_TIME_FORMAT,
};
