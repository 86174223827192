<template>
<div class='b-plan_table__label__wrapper'
     :class='{ "b-plan_table__label__wrapper--small": eventHeight <= 15 }'>
    <div class='b-plan_table__label'
         :class='{"b-plan_table__transparent": cardData.origin === `from_unavailability`}'>
        <div v-if='time'
             class='b-plan_table__label--header qa-plan-table-card__time'>
            {{ time }}
        </div>
        <div class='h-bold h-overflow-hidden'>
            <p v-if='cardData.origin === `from_unavailability`'
               class='h-m-0 b-plan_table__label--small qa-plan-table-card__unavailability-title'
               :class='{"b-plan_table__label__overflow-text": eventHeight <= 70}'>
                {{ $t(unavailabilityTitle) }}
            </p>
            <p v-else
               class='h-m-0 qa-plan-table-card__title'
               :class='{"b-plan_table__label__overflow-text": eventHeight <= 70}'>
                {{ privateEvent ? $t('EVENT.PRIVATE.TITLE') : cardData.title }}
            </p>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { CalendarInstanceType } from '@/types/Events/CalendarInstanceType';
import DateMixin from '@/mixins/dateMixin';
import { unavailabilityTitleTranslate } from '@/helpers/translate';

@Component
export default class PlanTableLanchCard extends Mixins(DateMixin) {
    @Prop({ type: Object, required: true }) readonly cardData!: CalendarInstanceType;
    @Prop({ type: Number, default: null }) readonly wrapperHeight!: number | null;
    eventHeight: number | null = null;
    $refs!: {
        infoBox: HTMLElement
    };

    get privateEvent(): boolean {
        return this.cardData.is_private && this.cardData.user_id !== this.user.id;
    }

    get checkEventHeight(): null | number {
        return this.$refs.infoBox ? this.$refs.infoBox.clientHeight : null;
    }

    get unavailabilityTitle() {
        return unavailabilityTitleTranslate(this.cardData.title);
    }

    get time(): null | string | TranslateResult {
        if (this.cardData.start_from_morning || this.cardData.fully_day) {
            return null;
        }
        if (this.cardData.full_day) {
            return this.$i18n.t('CALENDAR.FULL.DAY');
        }
        return this.getCurrentDateAsMinutesAMPM(new Date(this.cardData.dt_start_view || this.cardData.dt_start), false);
    }

    get color() {
        const colorsData: Array<string> = this.cardData.color.split(' - ');
        return `linear-gradient(${colorsData[0]}, ${colorsData[1]})`;
    }

    mounted() {
        this.eventHeight = this.checkEventHeight;
    }
}
</script>

<style lang='sass'>
.b-plan_table__label
    height: 100%
    background-color: #fff
    color: $dark-blue
    font-size: 12px
    padding: 4px 7px 4px 12px
    position: relative
    border-radius: 5px
    box-shadow: 0 5px 11px 0 rgba(33,63,107,0.1)
    overflow: hidden

    &--header
        font-weight: 300

    &--header, &__overflow-text
        width: 75px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap

    &--small
        text-transform: lowercase

    &__wrapper
        padding: 0 4px

        &--small
            padding: 0px 4px 0px 4px

    &__before
        left: 0
        width: 5px
        height: 100%
        position: absolute
        top: 0
        border-radius: 5px 0 0 5px

    &__appointment
        right: 0
        width: 8px
        height: 8px
        position: absolute
        background: #FB7C07
        bottom: 0
        border-radius: 5px 0 5px 0

.b-plan_table__transparent
    background: #EEF0F4
</style>
